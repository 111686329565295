@font-face {
  font-family: 'GT Walsheim';
  src: url('./fonts/GT\ Walsheim\ Regular.ttf');
}

:root {
  --primary: #2e2e85;
  --secondary: #f4f4ec;
  --background: #f4f4ec;
  --header-font: 'GT Walsheim';
  --animation-time-header: 0.2s;
  --footer-background: #4a4aa3; /* Choose one of the suggested colors */
  background-color: var(--background);
}

* {
  font-family: 'GT Walsheim';
}

html {
  scroll-behavior: smooth;
}

.website-logo-pc {
  margin-right: 1em;
  height: 50px;
  padding-top: 0.9em;
}

.website-logo-mobile {
  margin-right: 1em;
  height: 50px;
  padding-top: 0.9em;
}

.website-title {
  color: var(--primary);
  font-weight: 900;
  transition: color var(--animation-time-header) ease-in-out;
}

.website-title.scrolled {
  color: black;
  opacity: 90%;
}

.link {
  margin: 0 1em;
  text-decoration: none;
  color: var(--primary);
  font-weight: 100;
  transition: color var(--animation-time-header) ease-in-out;
}

.link.scrolled {
  color: black;
  opacity: 90%;
}

.contact-button {
  margin: 0 1em;
  padding: 0.35em 0.5em;
  text-decoration: none;
  color: white;
  background-color: var(--primary);
  border-radius: 50px;
  font-weight: bold;
  font-size: 1.2em;
  transition: background-color var(--animation-time-header) ease-in-out;
}

.contact-button.scrolled {
  background-color: #2cc302;
}

.cover {
  background-image: url("/src/images/Image\ SVG.svg");
  background-repeat: no-repeat;
  background-position: top right;
  background-color: var(--secondary);
  background-origin: content-box;
  width: 100%;
  height: 55vh;
  padding-top: 50px;
}

.cover-text {
  color: var(--primary);
  width: 50%;
  margin-left: 100px;
}

.cover h1 {
  margin-top: 0;
  padding-top: 50px;
}

.reviews-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 100px;
  padding: 25px;
  border-radius: 25px;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.review-info {
  list-style-type: none;
  padding-left: 0;
  opacity: 70%;
}

.page-button {
  margin: 10px;
  background: rgba(0, 0, 0, 0.1);
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.page-button.active {
  font-weight: bold;
  border: 1px solid black;
}

.about-us {
  width: 60%;
  margin: auto;
  text-align: left;
  margin-bottom: 100px;
}

.page-title {
  text-align: center;
  font-size: 4em;
  font-weight: bold;
  margin-bottom: 20px;
}

.content {
  font-size: 1em;
  line-height: 1.5;
}

.aanbod {
  width: 31%;
  margin: auto;
  text-align: left;
  margin-bottom: 100px;
}

.columns {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  border-radius: 25px;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.lesvorm-img-mobile {
  width: 100%;
  border-radius: 25px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.lesvorm-img-pc {
  width: 100%;
  height: 100%;
  border-radius: 25px 25px 0 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
.lesvorm-img-detail-pc {
  width: 100%;
  height: 100%;
  border-radius: 25px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.lesvormen {
  width: 70%;
  margin: auto;
  text-align: left;
}

.column {
  margin: 20px;
}

.column.right {
  width: 50%;
}

.price {
  display: flex;
  justify-content: left;
  width: 180px;
}
.price h4 {
  margin-right: 5px;
}

.price-crossed {
  text-decoration: line-through;
  opacity: 70%;
}

.aanvragen {
  padding: 0.25em 1em;
  text-decoration: none;
  color: white;
  background-color: #2cc302;
  border-radius: 50px;
  font-weight: bold;
  font-size: 1.2em;
  width: 100px;
  text-align: center;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
  background-color: white;
  border-radius: 25px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  color: var(--primary);
  margin-bottom: 100px;
}

.contact-form-mobile {
  border-radius: 0;
  background: transparent;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0);
}

.contact-form label {
  padding-left: 20px;
  align-self: start;
  margin: 10px 0;
  font-weight: bold;
}

.contact-form input,
.contact-form textarea {
  width: 85%;
  padding: 10px;
  border: 1px solid var(--primary);
  border-radius: 5px;
  margin-bottom: 20px;
  resize: none;
}

.contact-form textarea {
  height: 200px;
}

.contact-form input[type="submit"] {
  width: 150px;
  color: white;
  background-color: var(--primary);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  align-self: flex-start;
  font-weight: bold;
  margin-left: 20px;
}

.contact-form input[type="submit"]:hover {
  background-color: #2cc302;
}

.columns-contact {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  padding: 20px;
}

.column-contact {
  width: 100%;
  margin: 20px;
}

.contact-icon {
  width: 50px;
  height: 50px;
}

.icons-container {
  width: 300px;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  place-items: center;
}

.round {
  border-radius: 25px;
}

.white {
  background-color: white;
}

.shadow {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
